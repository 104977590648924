<template>
  <div class="main">
      <div class="box">
        <span @click="goBack"></span>
        <img src="../../assets/img/jieshao.png" alt="">
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}

</script>
<style lang="scss" scoped>
    .main {
        width: 100%;
        .box {
            text-align: center;
            position: relative;
            width: 1300px;
            margin: 0 auto;
            height: auto;
            text-align: center;
            span {
                position: absolute;
                display: block;
                width: 50px;
                height: 50px;
                background-color: transparent;
                right: 85px;
                top: 80px;
                cursor: pointer;
            }
            img {
                text-align: center;
            }
        }
    }
</style>